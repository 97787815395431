import AddCircle from '@mui/icons-material/AddCircle';
import React from "react";

const Package = () => {
  return (
    <div className=" flex flex-col m-auto  w-full h-full ">
      <h3 className="font-head m-auto  text-center mt-1 font-bold text-4xl underline decoration-red">
        PACKAGES
      </h3>
      <div className="grid  md:ml-12  w-full h-full md:grid-cols-3 md:grid gap-8 mb-4 mt-4" >
      <div className=" mt-1 flex flex-col  m-auto  bg-white s  focus:border-red  border-4  border-red   hover:shadow-red md:shadow-xl md:border-grey md:border-2 rounded-md w-11/12 h-auto md:w-3/4 md:m-0  ">
      <div className=" mx-auto mt-2 bg-red rounded-md w-3/4 font-head text-white text-center text-4xl">
          ELITE 
        </div>
        <div className=" decoration-black  underline mx-auto  mt-2 text-base font-head">
          Interior Iclusions
        </div>
        <div className=" mx-4 w-4/5 flex flex-col ">
          <ul className=" text-start marker:text-red list-disc">
            <li className="">Full vacuum with our PowerBrush system </li>
            <li className=""> Glovebox and storage compartments cleaned</li>
            <li className="">Air-con vents detailed </li>
            <li className=""> Fabric seats extraction shampooed</li>
            <li className=""> Leather seats deep cleaned</li>
            <li className=""> Headliner cleaned </li>
            <li className="">Deep steam clean </li>
            <li>Dash UV protected</li>
            <li>Interior glass cleaned</li>
          </ul>
        </div>
        <div className=" decoration-black underline mx-auto mt-2 text-base font-head">
          Exterior Iclusions
        </div>
        <div className=" mx-4 w-4/5 flex flex-col ">
          <ul className=" text-start marker:text-red list-disc">
          <li> The vehicle is washed using PH neutral shampoo to prevent any stones and sharp edged particles from being carried onto the vehicle via wash mitt.</li>
              <li> Wheels, arches and callipers are cleaned using a Ph neutral cleaner</li>
              <li>Door shuts, petrol cap, arches and lower sections parts will be cleaned </li>
              <li>Door jambs cleaned.</li>
              <li>The vehicle is dried using plush microfibre towels.</li>
              <li>Wheels glossed.</li>
              <li>Exterior glass cleaned</li>
          </ul>
        </div>
        <div className="mb-2 bg-red rounded-md m-auto  w-4/5 flex flex-col ">
          <div className=" m-auto flex gap-2">
            <ul className="  text-center font-head text-lg ">
              <li> Saloon - </li>
              <li> Mid-Suv- </li>
              <li>SUV - </li>
            </ul>
            <ul className="  text-center font-head text-lg ">
              <li>
                <span className="text-white ">KES 5000 </span>
              </li>
              <li>
                <span className="text-white ">KES 6600 </span>
              </li>
              <li>
                <span className="text-white ">KES 7200 </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="  mt-1 flex flex-col  m-auto  bg-white s  focus:border-red  border-4  border-red   hover:shadow-red md:shadow-xl md:border-grey md:border-2 rounded-md w-11/12 h-auto md:w-3/4 md:m-0  ">
      <div className=" mx-auto mt-2 bg-red rounded-md w-3/4 font-head text-white text-center text-4xl">
          PRESTIGE 
        </div>
        
    
        <div className=" mx-auto mt-4 bg-red rounded-md w-1/2 font-head text-white text-center text-xl">
          ELITE PACKAGE
        </div>
        <AddCircle sx={{ fontSize: 40 }} className='  font m-auto mt-2 md:mt-12'/>
        <div className=" decoration-black underline mx-auto  text-base font-head">
          Engine Steam Detailing
        </div>
        <div className=" mx-4 w-4/5 flex flex-col ">
          <ul className=" text-start marker:text-red list-disc">
          <li> Engined is rinsed using light pressure</li>
              <li>Steam is now used to soften debris and dirt</li>
              <li>
                All purpose cleaner is now applied and worked in with soft
                bristle detailing brush
              </li>
              <li>Engine is rinsed to lift away dirt </li>
              <li>Components are now carefully dried </li>
              <li>Dressing is now applied to protect and rejuvenate</li>
          </ul>
        </div>
        <div className="mb-2 bg-red rounded-md m-auto  w-4/5 flex flex-col ">
          <div className=" m-auto flex gap-2">
            <ul className="  text-center font-head text-lg ">
              <li> Saloon - </li>
              <li> Mid-Suv- </li>
              <li>SUV - </li>
            </ul>
            <ul className="  text-center font-head text-lg ">
              <li>
                <span className="text-white ">KES 7000 </span>
              </li>
              <li>
                <span className="text-white ">KES 8600 </span>
              </li>
              <li>
                <span className="text-white ">KES 9700 </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="  mt-1 flex flex-col  m-auto  bg-white s  focus:border-red  border-4  border-red   hover:shadow-red md:shadow-xl md:border-grey md:border-2 rounded-md w-11/12 h-auto md:w-3/4 md:m-0  ">
      <div className=" mx-auto mt-2 bg-red rounded-md w-3/4 font-head text-white text-center text-4xl">
          MAJESTIC 
        </div>
        
    
        <div className=" mx-auto mt-4 bg-red rounded-md w-1/2 font-head text-white text-center text-xl">
          Prestige PACKAGE
        </div>
        <AddCircle sx={{ fontSize: 40 }} className='  font m-auto mt-2 md:mt-12'/>
        <div className=" decoration-black underline mx-auto  text-base font-head">
          Paint Correction / Buffing
        </div>
        <div className=" mx-4 w-4/5 flex flex-col ">
          <ul className=" text-start marker:text-red list-disc">
              <li> The paintwork is treated with a clay bar removing bonded surface
contaminants </li>
            <li>The paint is treated with an abrasive cutting compound which helps remove any swirl marks and light scratches</li>
            <li>The next step, the paint is polished to a high gloss
IPA is used to remove all traces of oils from the polishing phase to enhance the performance of the sealant </li>
            <li>Exterior plastics and door trims are dressed</li>
            <li>Tyres are dressed</li>
          </ul>
        </div>
        <div className="mb-2 bg-red rounded-md m-auto  w-4/5 flex flex-col ">
          <div className=" m-auto flex gap-2">
            <ul className="  text-center font-head text-lg ">
              <li> Saloon - </li>
              <li> Mid-Suv- </li>
              <li>SUV - </li>
            </ul>
            <ul className="  text-center font-head text-lg ">
              <li>
                <span className="text-white ">KES 12000 </span>
              </li>
              <li>
                <span className="text-white ">KES 14600 </span>
              </li>
              <li>
                <span className="text-white ">KES 16700 </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="  mt-1 flex flex-col  m-auto  bg-white s  focus:border-red  border-4  border-red   hover:shadow-red md:shadow-xl md:border-grey md:border-2 rounded-md w-11/12 h-auto md:w-3/4 md:m-0  ">
      <div className=" mx-auto mt-2 bg-red rounded-md w-3/4 font-head text-white text-center text-4xl">
          ROYALE
        </div>
        
    
        <div className=" mx-auto mt-4 bg-red rounded-md w-1/2 font-head text-white text-center text-xl">
          MAJESTIC PACKAGE
        </div>
        <AddCircle sx={{ fontSize: 40 }} className='  font m-auto mt-2 md:mt-12'/>
        <div className=" decoration-black underline mx-auto  text-base font-head">
         
        </div>
        <div className=" mx-4 w-4/5 flex flex-col ">
          <ul className=" text-start marker:text-red list-disc">
          <li>Rim and Brake Dust Cleaning </li>
          <li>Watermark Removal  </li>
          <li>Headlight Restoration</li>
          
              
          </ul>
        </div>
        <div className="mb-2 bg-red rounded-md m-auto  w-4/5 flex flex-col ">
          <div className=" m-auto flex gap-2">
            <ul className="  text-center font-head text-lg ">
              <li> Saloon - </li>
              <li> Mid-Suv- </li>
              <li>SUV - </li>
            </ul>
            <ul className="  text-center font-head text-lg ">
              <li>
                <span className="text-white ">KES 25000 </span>
              </li>
              <li>
                <span className="text-white ">KES 28100 </span>
              </li>
              <li>
                <span className="text-white ">KES 30200 </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Package;
