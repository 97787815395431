import React from 'react'

const Pricing = () => {
  return (
    <div className='flex flex-col h-full w-screen '>
        <div>

        </div>
        <div className=' mx-2 w-full '>
        <table class="w-full font-pg ">
  <thead class="font-head" >
    <tr>
      <th></th> 
      <th class=" border border-slate-600 ... md:text-lg ">Saloon</th>
      <th class=" border border-slate-600 ... md:text-lg">Mid-SUV</th>
      <th class=" border border-slate-600 ... md:text-lg">Suv</th>
    </tr>
  </thead>
  <tbody >
    <tr>
      <td class=" font-head md:text-lg ">Interior Detailing </td>
      <td class="text-center text-base"> Ksh 5000</td>
      <td class="text-center text-base"> Ksh 6600</td>
      <td class="text-center text-base">Ksh 7200</td>
    </tr>
    <tr>
      <td class="font-head md:text-lg">Engine Steam Detailing</td>
      <td class="text-center text-base">Ksh 2000</td>
      <td class="text-center text-base">Ksh 2000</td>
      <td class="text-center text-base">Ksh 2500</td>

    </tr>
    <tr>
      <td class=" font-head md:text-lg ">Headlight Restoration </td>
      <td class="text-center text-base"> Ksh 4000</td>
      <td class="text-center text-base"> Ksh 4000</td>
      <td class="text-center text-base">Ksh 4000</td>
    </tr>
    <tr>
      <td class="font-head md:text-lg">Paint Correction/Buffing</td>
      <td class="text-center text-base">Ksh 5000</td>
      <td class="text-center text-base">Ksh 6000</td>
      <td class="text-center text-base">Ksh 7000</td>
    </tr>
    <tr>
      <td class="font-head md:text-lg">Watermark Removal</td>
      <td class="text-center text-base">Ksh 5000</td>
      <td class="text-center text-base">Ksh 5500</td>
      <td class="text-center text-base">Ksh 5500</td>
    </tr>
    <tr>
      <td class="font-head md:text-lg">Rim cleaning /Polishing </td>
      <td class="text-center text-base">Ksh 4000</td>
      <td class="text-center text-base">Ksh 4000</td>
      <td class="text-center text-base">Ksh 4000</td>

    </tr>
    <tr>
      <td class="font-head md:text-lg">Full Detailing Job</td>
      <td class="text-center font-head text-base">Ksh 25000</td>
      <td class="text-center font-head text-base">Ksh 28100</td>
      <td class="text-center font-head text-base">Ksh 30200</td>
    </tr>
  </tbody>
</table>
        </div>
        <div  className='flex flex-col mx-2 mt-6 md:w-1/2 m-auto '>
            <h3 className='text-center text-xl font-head underline decoration-red decoration-4'>Full Detailing Job</h3>
            
            <p className=' font-pg text-lg'><span className='font-head '>Exterior detailing:</span> The vehicle is washed, clay barred, polished and waxed. The paint is thoroughly cleaned, restored and protected.</p>
            <p className=' font-pg text-lg'> <span className='font-head '>Interior detailing:</span> The interior of the vehicle is thoroughly cleaned, including shampooing the carpets and upholstery, cleaning and conditioning the leather, and detailing the dashboard and other surfaces.
   </p>
    <p className=' font-pg text-lg'><span className='font-head '>Engine detailing:</span> The engine bay is cleaned and dressed to remove grime and dirt.</p>
    <p className=' font-pg text-lg'> <span className='font-head '>Additional Services:</span> such as wheel and tire cleaning and headlight restoration.
            </p>
        </div>

    </div>
  )
}

export default Pricing