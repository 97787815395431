import React, { useRef } from "react";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import emailjs from '@emailjs/browser';
import Slider from "../components/Slider";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zghvd7o', 'template_vs8e89a', form.current, '92DMt1KF5hIM7h7ga')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      form.current.reset();
  };
  return (
    <div className="flex flex-col w-screen h-full lg:h-full">
      <Slider/>

      <div className="flex flex-col-reverse gap-4  w-screen h-auto lg:flex-row  lg:mb-16">
      <div className="flex flex-col w-full h-auto  lg:w-1/2 lg:h-auto ">
          <div className="flex flex-col items-center  ">
            <h3 className="font-head text-xl lg:text-2xl mb-2 underline decoration-red mt-4 decoration-4">Talk to Us</h3>
            <p className='w-4/6 text-center font-pg text-base lg:text-start md:text-lg '>
            At Duckman auto detailing  we take pride in each project we take ,from protection to restoration ,you can be safe
                in the knowledge that your caris in good hands,our eco-friendly solutions provide unbelievable results!
            </p>
          </div>

          <div className="flex flex-col  my-4  ml-8 font-pg gap-2 text-black  lg:ml-24 md:text-lg">
            <div>
              {" "}
              <LocationOnIcon className=" text-red rounded" />Nairobi Kenya
            </div>
            <div>
              <WhatsAppIcon className="  text-[#075E54] rounded" /> +254702982035
            </div>
            <div>
              <EmailIcon className=" text-main rounded" /> info@duckmanautodetailing.com
            </div>
          </div>

          <div className="flex mt-2  ml-8 mb-4 gap-4  lg:ml-24 ">
          <a href="https://www.facebook.com/profile.php?id=100086023751331" ><FacebookIcon className=" text-[#4267B2] rounded" /></a>
          <a href="https://twitter.com/DuckmanAuto" ><TwitterIcon className=" text-[#00acee] rounded" /></a>
          <a href="https://www.instagram.com/duckmanautodetailing" ><InstagramIcon className=" text-[#ee2e2e] rounded" /></a>
          
        
          </div>
        </div>

        <div className="flex flex-col items-center w-full  h-auto lg:w-1/2 ">
          <h3 className='font-head text-xl mb-2 mt-4 underline decoration-red decoration-4 lg:text-2xl  lg:mb-0'> Send Message </h3>
          <form ref={form}  onSubmit={sendEmail} className="w-full h-auto m-auto font-pg text-base  flex flex-col  gap-2 flex-start lg:mt-4 md:text-lg">
            <input
              type="text"
              placeholder="Your Name"
              name="name" 
              className="w-11/12 border-2 border-solid border-black rounded h-[30px] m-auto p-4 outline-none lg:w-8/12 "
            />
            <input
              type="email"
              placeholder="Your Email"
              name="email"
              className="w-11/12 border-2 border-solid border-black rounded h-[30px] m-auto p-4  outline-none lg:w-8/12"
            />
            <textarea
             name="message"
              placeholder="Message"
              className="w-11/12 border-2 border-solid border-black rounded h-[50px] m-auto p-4 outline-none lg:w-8/12 lg:h-[150px]"
            />
            <button className="ml-6 bg-red text-base font-extrabold text-whitee w-[100px] h-[40px] lg:ml-28">
              Submit{" "}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
