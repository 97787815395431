import React, { useRef, useState } from "react";
import { toast } from 'react-toastify';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import emailjs from '@emailjs/browser';
import Slider from "../components/Slider";
import sent from'../assets/images/sent1.webp'



const Book = () => {
  const form = useRef();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
  
    const selectedServices = Array.from(
      form.current.elements.categories
    ).reduce((services, checkbox) => {
      if (checkbox.checked) {
        services.push(checkbox.value);
      }
      return services;
    }, []);
  
    const formData = new FormData(form.current);
    formData.append('services', selectedServices.join(', '));
  
    const emailData = {
      name: formData.get('name'),
      email: formData.get('email'),
      tel: formData.get('tel'),
      Model: formData.get('Model'),
      categories: selectedServices.join(', '),
      message: formData.get('message'),
      location: formData.get('location'),
    };
  
    emailjs
      .send('service_zghvd7o', 'template_e7haqof', emailData, '92DMt1KF5hIM7h7ga')
      .then(
        (result) => {
          console.log(result.text);
          setIsFormSubmitted(true);
          toast.success("Booking has been sent successfully!");
        },
        (error) => {
          console.log(error.text);
          toast.error('Failed to submit booking. Please try again.');
        }
      );
  
    form.current.reset();
  };
  
  
  return (
    <div className="flex flex-col w-screen items-center h-full lg:h-full">
      <Slider/>

      <div className="flex flex-col-reverse gap-4  w-screen h-auto lg:flex-row  lg:mb-16">
        <div className="flex flex-col w-full h-auto  text-base font-pg  lg:w-1/2 lg:h-auto md:text-lg  ">
          <div className="flex flex-col items-center  ">
            <h3 className="font-head text-xl lg:text-2xl mb-2 underline decoration-red mt-4 decoration-4">Talk to Us</h3>
            <p className='w-4/6 text-center lg:text-start '>
            At Duckman auto detailing  we take pride in each project we take ,from protection to restoration ,you can be safe
                in the knowledge that your caris in good hands,our eco-friendly solutions provide unbelievable results!
            </p>
          </div>

          <div className="flex flex-col  my-4  ml-8  gap-2 text-black  lg:ml-24">
            <div>
              {" "}
              <LocationOnIcon className=" text-red rounded" /> Nairobi,Kenya 
            </div>
            <div>
              <WhatsAppIcon className="  text-[#075E54] rounded" /> +254702982035
            </div>
            <div>
              <EmailIcon className=" text-main rounded" /> info@duckmanautodetailing.com
            </div>
          </div>

          <div className="flex mt-2  ml-8 mb-4 gap-4  lg:ml-24 ">
          <a href="https://www.facebook.com/profile.php?id=100086023751331" ><FacebookIcon className=" text-[#4267B2] rounded" /></a>
          <a href="https://twitter.com/DuckmanAuto" ><TwitterIcon className=" text-[#00acee] rounded" /></a>
          <a href="https://www.instagram.com/duckmanautodetailing" ><InstagramIcon className=" text-[#ee2e2e] rounded" /></a>
          
        
          </div>
        </div>
        

        <div className="flex flex-col items-center w-full  h-auto lg:w-1/2 ">
          <h3 className='font-head text-xl mb-2 mt-4 underline decoration-red decoration-4 lg:text-2xl  lg:mb-0'> Book With Us Today </h3>
          {isFormSubmitted ? (
            <div className="w-full h-auto m-auto text-lg font-head  flex flex-col  gap-2 lg:mt-4 md:text-lg">
            <img className=" h-20 w-20 m-auto" src={sent} alt=""/>
             <div className=" m-auto ">
              <p>Thank you for your booking!</p>
             <p>We will contact you shortly.</p>
             </div>
             
           </div>
          ) : (
            <form ref={form}  onSubmit={sendEmail} className="w-full h-auto m-auto text-base font-pg flex flex-col  gap-2 flex-start lg:mt-4 md:text-lg">
            <input
            required
              type="text"
              placeholder="Your Name"
              name="name" 
              className="w-11/12 border-2 font-pg border-solid border-black rounded h-[30px] m-auto p-4 outline-none lg:w-8/12 "
            />
            <input 
              required
              type="email"
              placeholder="Your Email"
              name="email"
              className="w-11/12 border-2 border-solid font-pg border-black rounded h-[30px] m-auto p-4  outline-none lg:w-8/12"
            />
             <input
              required
              type="tel"
              placeholder="Your Phone Number"
              name="tel"
              className="w-11/12 border-2 border-solid font-pg border-black rounded h-[30px] m-auto p-4  outline-none lg:w-8/12"
            />
            <input
            required
              type="text"
              placeholder="Location"
              name="location" 
              className="w-11/12 border-2 font-pg border-solid border-black rounded h-[30px] m-auto p-4 outline-none lg:w-8/12 "
            />
             <input
              type="text"
              required
              placeholder="Vehicle Model"
              name="Model" 
              className="w-11/12  border-2 font-pg border-solid border-black rounded h-[30px] m-auto p-4 outline-none lg:w-8/12 "
            />
             <div className="w-11/12 m-auto lg:w-8/12">
          <p className=" font-medium text-xl font-head">Select Service(s):</p>
          <div className="flex flex-col gap-2">
            <label>
              <input type="checkbox" name="categories" value="Interior" />
              Interior steam detailing
            </label>
            <label>
              <input
                type="checkbox"
                name="categories"
                value="Paint correction"
              />
              Paint Correction / Protection
            </label>
            <label>
              <input type="checkbox" name="categories" value="Engine" />
              Engine Steam Detailing
            </label>
            <label>
              <input
                type="checkbox"
                name="categories"
                value="Headlight Restoration"
              />
              Headlight Restoration
            </label>
            <label>
              <input
                type="checkbox"
                name="categories"
                value="Watermark Removal"
              />
              Watermark removal
            </label>
          </div>
        </div>
            <textarea
            name="message"
              placeholder="Additional Information"
              className="w-11/12 border-2 border-solid border-black rounded h-[50px] m-auto p-4 outline-none lg:w-8/12 lg:h-[150px]"
            />
            <button className="ml-6 bg-red text-white text-base font-extrabold w-[100px] h-[40px] lg:ml-28">
              Send{" "}
            </button>
          </form>
          )}
          
        </div>
        
      </div>
     
    </div>
  );
};

export default Book;