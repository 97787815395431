import React from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link } from "react-router-dom";
import logo from "../assets/images/duckmanalogo.png";

const Footer = () => {
  return (
    <div className="flex flex-col bg-footer bg-cover h-auto">
      <div className="flex flex-col self-center md:-mb-32 ">
      <div className="h-12 align-middle m-auto mt-[4px] md:self-center  ">
          <Link to='/'><img className="w-14 h-14 md:w-16 md:h-16" src={logo} alt="" /> </Link>
          </div>
          <div className="m-auto self-center mt-4 ">
            <h3 className="font-pg font-light text-base m-auto text-center text-red"> keep it clean,<span className="text-whitee">keep it shinny</span></h3>
          </div>
      </div>
        <div className="flex flex-col items-start    gap-4 bg-no-repeat bg-top md:flex-row  md:gap-20">
      
      <div className="flex flex-col items-start mt-8 ml-6 gap-4 md:mt-44 md:ml-24  md:mb-5 ">
        <div className="flex gap-2">
          <LocalPhoneIcon className="text-red " />
          <h1 className="text-whitee  text-base font-extralight font-pg">
          +254702982035
          </h1>
        </div>
        <div className="flex gap-2">
          <LocationOnIcon className="text-red" />
          <h1 className="text-whitee  text-base font-extralight font-pg">
          Nairobi ,Kenya
          </h1>
        </div>
        <div className="flex gap-2">
          <EmailIcon className="text-red" />
          <h1 className="text-whitee   text-base font-extralight font-pg">
            info@duckmanautodetailing.com
          </h1>
        </div>
        <div className="flex gap-2">
          <WhatsAppIcon className="text-red" />
          <a href='https://wa.me/<+254702982035>'> <h1 className="text-whitee  text-base font-extralight font-pg">
            +254702982035
          </h1>
          </a>
        </div>
      </div>
      <div className="flex flex-col items-start mt-4 ml-6 md:mt-44 ">
        <h3 className=" font-pg text-xl font-semibold text-whitee">Services</h3>
        <ul className="text-whitee list-disc list-inside   text-base font-extralight font-pg ">
          <li><Link to="/interior" className="font-pg">
            Interior Steam Detailing
          </Link></li>
          <li><Link to="/paintcorrection" className="font-pg">
            Paint Correction/Protection
          </Link></li>
          <li><Link to="/engine" className="font-pg">
            Engine Steam Detailing
          </Link></li>
         <li> <Link to="/headlight" className="font-pg">
            Headlight Restoration
          </Link></li>
          <li><Link to="/watermark" className="font-pg">
            Watermark Removal
          </Link></li>
        </ul>
      </div>
      <div className="flex flex-col items-start mt-4 ml-6 md:mt-44 ">
        <h3 className=" font-pg text-xl font-semibold text-whitee">Menu</h3>
        <ul className="text-whitee list-disc list-inside   text-base font-extralight font-pg ">
          <li><Link to="/blogs" className="font-pg">
            Blog
          </Link></li>
          <li><Link to="/gallery" className="font-pg">
            Gallery
          </Link></li>
          <li><Link to="/contact" className="font-pg">
            Contact
          </Link></li>
         <li> <Link to="/booking" className="font-pg">
            Book Service
          </Link></li>
          
        </ul>
      </div>
      <div className="flex flex-col items-start mt-4 ml-6 md:mt-44  ">
        <h3 className=" font-pg text-xl font-semibold text-whitee">
          Follow Us
        </h3>
        <ul className="text-red  flex gap-2 text-base font-extralight font-pg ">
          <li>
          <a href="https://www.facebook.com/profile.php?id=100086023751331" ><FacebookIcon className=" text-[#4267B2] rounded" /></a>
          </li>
          <li>
          <a href="https://twitter.com/DuckmanAuto" ><TwitterIcon className=" text-[#00acee] rounded" /></a>
          </li>
          <li>
          <a href="https://www.instagram.com/duckmanautodetailing" ><InstagramIcon className=" text-[#ee2e2e] rounded" /></a>
          </li>
          
        </ul>
      </div>
    </div>
    </div>
  
  );
};

export default Footer;
